import axiosInstance from "./axios.instance";
import axios from "axios";

const API_GET_QUICK_PRODUCTS = async (
  pageNo,
  limit,
  id,
  filterData,
  globalFilterValue
) => {
  try {
    var resp;
    if (filterData || globalFilterValue) {
      console.log(globalFilterValue)
      let allFilter = "";
      if (filterData) {
        filterData.forEach((element) => {
        allFilter += `&${element.key}=${element.value}`;
      });
      }
      console.log(allFilter)
      if (globalFilterValue) {
        allFilter += `&global=${globalFilterValue}`;
      }
      resp = await axiosInstance.get(
        `/product/quick/${id}?page=${pageNo}&limit=${limit}&isActive=1${allFilter}`
      );
      console.log(resp)
    } else {
      resp = await axiosInstance.get(
        `/product/quick/${id}?page=${pageNo}&limit=${limit}&isActive=1`
      );
      console.log(resp)
    }
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_QUICK_CATEGORY = async (id) => {
  try {
    let resp = await axiosInstance.get(`/category/quick/${id}?page=${0}&limit=${100000000}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_QUICK_ORDER = async (id,data) => {
  try {
    let resp = await axiosInstance.post(`/order/quick/${id}`,data);
    return resp.data;
  } catch (err) {
    throw err;
  }
};



export { API_GET_QUICK_PRODUCTS, API_GET_QUICK_CATEGORY,API_QUICK_ORDER };
