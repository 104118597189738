// Home.js

import React, { useState, useEffect } from "react";
import Products from "../Products";
import './style.css'
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetails, getCompanyId } from "../../reducers/companySlice";

const Home = () => {
  const { companyData, companyId } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const [buttonStyle, setButtonStyle] = useState({});
  const [style, setStyle] = useState({}); 
  const sendWhatsAppMessage = () => {
    const phoneNumber = companyData.personalPhone;
    const bussinessNumber = companyData.phone;
    const scanNumber = bussinessNumber? bussinessNumber: phoneNumber;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${scanNumber}&text=Hi`;
    window.open(whatsappUrl, "_blank");
  };

  useEffect(() => {
    // Check if theme object exists in companyData.desc
    if (companyData?.desc?.theme) {
      // Extract colors from the received data
      const { buttonbg, buttonColor } = companyData?.desc?.theme;

      // Update the dynamic styles for the button
      setStyle({
        buttonbgColor: buttonbg || '#075E54',
        buttonColor: buttonColor || '#ffffff', // Use the primaryColor as the button color
      });
    }
  }, [companyData]);

  return (
    <div>
      <div className="min-w-min mt-8 flex flex-column justify-content-center align-items-center">
        <img src={companyData?.url} className="w-2 lg:w-1" />
        <div className="w-12 lg:w-8 mt-5 flex justify-content-center align-items-center text-center">
          <p className="__contentSPan">{companyData?.companyName}</p>
        </div>
        <div className="w-12 lg:w-8 flex justify-content-center align-items-center text-center">
          <p className="__contentSPan1">{companyData?.desc?.subheading}</p>
        </div>
        <div>
          <div className="my-5">
            <Button
              className="mt-2 p-3 px-5 __btn2"
              label="Shop Now from Whatsapp"
              onClick={sendWhatsAppMessage}
              style={{
                backgroundColor: style.buttonbgColor,
                color: style.buttonColor,
              }}// Apply dynamic styles for the button
            />
          </div>
        </div>
        <div className="w-12 lg:w-10">
          <Products />
        </div>
      </div>
    </div>
  );
};

export default Home;
