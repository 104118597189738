import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_QUICK_CATEGORY,
  API_GET_QUICK_PRODUCTS,
} from "../api/product.service";

const initialState = {
  productsData: [],
  totalProductCount: 0,
  loading: false,
  selectedProduct: null,
  loading:false,
  page: 0,
  limit: 10,
  mode: null,
  catagories: [],
  selectedProductsList: [],
};

export const getQuickProducts = createAsyncThunk(
  "productTable/getQuickProducts",
  async ({ pageNo, limit, id, filterData, globalFilterValue }, thunkAPI) => {
    try {
      let products = await API_GET_QUICK_PRODUCTS(pageNo, limit, id, filterData, globalFilterValue);
      return products;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getCategory = createAsyncThunk(
  "productTable/getCategory",
  async (id, thunkAPI) => {
    try {
      let resp = await API_GET_QUICK_CATEGORY(id);
      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const productTableSlice = createSlice({
  name: "productTable",
  initialState,
  reducers: {
    changeMode(state, action) {
      state.mode = action.payload;
    },

    resetMode(state) {
      state.mode = null;
    },
    setproduct(state, action) {
      if (state.productData.length < state.limit) {
        state.productData = [action.payload, ...state.productData];
      }
      state.totalProductCount += 1;
    },
    changeSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },

    changePage(state, action) {
      state.page = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getQuickProducts.fulfilled, (state, action) => {
      state.totalProductCount = action.payload.count;
      state.productsData = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getQuickProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuickProducts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.catagories = action.payload.rows;
      state.loading = false;
    });
    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategory.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { changeMode, changeSelectedProduct, resetMode, changePage } =
  productTableSlice.actions;

export default productTableSlice.reducer;
