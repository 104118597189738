import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CustomButton } from "../../CustomButton/index.js";

import { classNames } from "primereact/utils";
import { API_QUICK_ORDER } from "../../../api/product.service.js";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { setProductInCart } from "../../../reducers/cartTableSlice.js";
import Loader from "../../Loader/index.js";

const OrderForm = () => {
  const toast = useRef(null);

  const { cartItemData, totalPriceOfItemInCart, totalItemInCart } = useSelector(
    (state) => state.cartTable
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyData, companyId } = useSelector((state) => state.company);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);


  const defaultValues = {
    name: "",
    address: "",
    mobileNumber: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  //function form get error message
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const modalLoad = () => {
    return (
      <div>
        <Loader visible={loading} />
      </div>
    );
  };

  const hide = () => {
    localStorage.removeItem("deskalaCartItem");
    const data = {
      totalPriceOfItemInCart: 0,
      cartItemData: [],
      totalItemInCart: 0,
    };
    dispatch(setProductInCart(data));
    setVisible(false);
   
  };



  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data, cartItemData);
    let products = [];
    cartItemData.forEach((product) => {
      products.push({
        currency: "INR",
        quantity: product.itemCount,
        item_price: product.price,
        product_retailer_id: product.SKUCode,
      });
    });
    if (cartItemData) {
      const __data = {
        companyId: companyId,
        phone: `91${data.mobileNumber}`,
        productItems: products,
        name: data.name,
      };
      const res = await API_QUICK_ORDER(companyId, __data);
      console.log(res);
      if (res) {
        setLoading(false);
        //toast.current.show({severity:'success', summary: 'Success', detail:'Order Successfully', life: 2000});
        reset();
        setVisible(true);
      }
      setLoading(false);
    }
  };

  const footer = () => {
    return <>h1</>;
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Order Successfully"
        visible={visible}
        className="w-10 lg:w-6"
        onHide={() => hide()}
      >
       <div className="flex flex-column justify-content-center align-items-center">
       <img className="w-8 mt-2" src="./3d-casual-life-e-commerce-completed-order.png" alt=""/>
        <p className="m-0 mt-3">
        Your order has been successfully ordered
        </p>
       </div>
      </Dialog>
      {modalLoad()}
      <div className={`card`}>
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <label
              htmlFor="Name"
              className={classNames({ "p-error": errors.name })}
            >
              Name *
            </label>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Please enter a category name." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  maxLength={20}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                  placeholder="Enter name"
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("categoryName")}
          </div>
          <div className="field">
            <label
              htmlFor="address"
              className={classNames({ "p-error": errors.name })}
            >
              Address *
            </label>
            <Controller
              name="address"
              control={control}
              rules={{ required: "Please enter address." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  maxLength={20}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                  placeholder="Enter address"
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("address")}
          </div>
          <div className="field">
            <label
              htmlFor="mobileNumber"
              className={classNames({ "p-error": errors.name })}
            >
              Mobile Number *
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: "Please enter mobile Number." }}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  maxLength={12}
                  className={classNames({ "p-invalid": fieldState.invalid })}
                  placeholder="Enter mobile number "
                  {...field}
                />
              )}
            />
            {getFormErrorMessage("mobileNumber")}
          </div>

          <div className="flex justify-content-end gap-2">
            <CustomButton
              varient="cancel w-full"
              type="button"
              label={"Order"}
              disabled={totalItemInCart === 0}
              onClick={handleSubmit(onSubmit)}
              themeColors={companyData?.desc?.theme}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderForm;
