import {configureStore} from "@reduxjs/toolkit";
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import productTableSlice from "./reducers/productTableSlice";
import cartTableSlice from "./reducers/cartTableSlice";
import companySlice from "./reducers/companySlice";


const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
  })

const store = configureStore({
    reducer : {
        productTable: productTableSlice,   
        cartTable:cartTableSlice, 
        company:companySlice 
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
      }),
})

export default store;