import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_COMPANY_DETAILS, API_GET_COMPANY_ID } from "../api/company.service";


const initialState = {
    companyData: null,
    companyId:null,
    loading: false,
  };

  
  export const getCompanyId = createAsyncThunk(
    "companyTable/getCompanyId",
    async (companyName, thunkAPI) => {
      try {
        let resp = await API_GET_COMPANY_ID(companyName);
        return resp;
      } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  );

  export const getCompanyDetails = createAsyncThunk(
    "companyTable/getCompanyDetails",
    async (id, thunkAPI) => {
      try {
        let resp = await API_GET_COMPANY_DETAILS(id);
        return resp;
      } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
      }
    }
  );
  
const companyTableSlice = createSlice({
    name: "companyTable",
    initialState,
    reducers: { 
        
    },

    extraReducers: (builder) => {
      builder.addCase(getCompanyDetails.fulfilled, (state, action) => {
        console.log(action.payload);
        state.companyData = action.payload.company;
        state.loading = false;
      });
      builder.addCase(getCompanyDetails.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getCompanyDetails.rejected, (state) => {
        state.loading = false;
      });

      builder.addCase(getCompanyId.fulfilled, (state, action) => {
        console.log(action.payload);
        state.companyId = action.payload.subDomain?.companyId;
        state.loading = false;
      });
      builder.addCase(getCompanyId.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getCompanyId.rejected, (state) => {
        state.loading = false;
      });
    }
  });
  
  export const { } = companyTableSlice.actions;
  export default companyTableSlice.reducer;
  