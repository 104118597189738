import './App.css';
import Footer from './componenets/Footer';
import Header from './componenets/Header';
import Cart from './view/Cart';
import Home from './view/Home';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyDetails, getCompanyId } from './reducers/companySlice';
import { setProductInCart } from './reducers/cartTableSlice';
import ScrollToTopOnMount from './componenets/ScrollToTopOnMount';


function App() {
  const dispatch = useDispatch();
  const {companyData,companyId } = useSelector((state) => state.company);

  
  useEffect(() => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const subdomain = url.hostname.split('.')[0];
    // const subdomain = 'shop';
    dispatch(getCompanyId(subdomain));
  }, []);

  useEffect(() => {
   if (companyId) {
    dispatch(getCompanyDetails(companyId))
    const items = JSON.parse(localStorage.getItem("deskalaCartItem"));
     console.log(items)
    if (items) {
      dispatch(setProductInCart(items))
    }
   }
  }, [companyId]);

  useEffect(()=>{
     const items = JSON.parse(localStorage.getItem("deskalaCartItem"));
     console.log(items)
     //dispatch(setProductInCart(items))
  },[])

  useEffect(()=>{
    if (companyData) {
       document.title = companyData?.companyName;  
      
       // Create a new link element
       const newLinkElement = document.createElement('link');
       newLinkElement.rel = 'icon';
       newLinkElement.href =  companyData.url;
   
       // Replace the existing link element with the new one
     
       document.head.appendChild(newLinkElement);
   
       // Cleanup function
       return () => {
         // Revert to the original link element when the component is unmounted (optional)
         document.head.removeChild(newLinkElement);
         
       };
    }
  },[companyData])


  return (
    <div className="App">
      <div className="body_app">
      <Header />
      <ScrollToTopOnMount />
        <Routes>
            <Route path='/' element={ <Home />} />
            <Route path='/cart' element={ <Cart />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
