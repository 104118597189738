import axiosInstance from "./axios.instance";
import axios from "axios";

const API_GET_COMPANY_DETAILS = async (id) => {
    try {
      let resp = await axiosInstance.get(`/company/quick/${id}`);
      return resp.data;
    } catch (err) {
      throw err;
    }
  }

  const API_GET_COMPANY_ID = async (name) => {
    try {
      let resp = await axiosInstance.get(`/subdomain/${name}`);
      return resp.data;
    } catch (err) {
      throw err;
    }
  }

  export { API_GET_COMPANY_DETAILS,API_GET_COMPANY_ID };
