import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import "./style.css";
import { Card } from 'primereact/card';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";

import { Skeleton } from 'primereact/skeleton';
        
import {
  changePage,
  getCategory,
  getQuickProducts,
} from "../../reducers/productTableSlice";
import {
  addProductInCart,
  removeProductInCart,
} from "../../reducers/cartTableSlice";
import { Paginator } from 'primereact/paginator';
import Loader from "../../componenets/Loader";

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [productsDataSkelton, setProductsDataSkelton] = useState([]);

  const dispatch = useDispatch();

  const { productsData, catagories, limit, page, totalProductCount ,loading} = useSelector(
    (state) => state.productTable
  );
  const { cartItemData, totalPriceOfItemInCart } = useSelector(
    (state) => state.cartTable
  );
  const { companyData, companyId } = useSelector((state) => state.company);


  const [layout, setLayout] = useState("list");

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);


  useEffect(() => {
    if (companyId) {
      dispatch(getCategory(companyId));
    }else{
      const item = []
      for (let i = 0; i < 8; i++) {
        item.push({name:"name"})
      }
      setProductsDataSkelton(item)
    }
  }, [companyId]);

  const onPageChange = (event) => {
    console.log(event)
    dispatch(changePage(event.page));
    setFirst(event.first);
    setRows(event.rows);
  };

  const modalLoad = () => {
    return (
      <div>
        <Loader visible={loading} />
      </div>
    );
  };

  useEffect(() => {
    if (!selectedCategory) {
      dispatch(getQuickProducts({ pageNo: page, limit: limit, id: companyId }));
    } else {
      console.log(selectedCategory);
      getProduct()
    }
  }, [selectedCategory, companyId, page]);

  function getProduct() {
    const filterData = [
      {
        key: "categoryId",
        value: selectedCategory.id,
      },
    ];

    if (globalFilterValue) {
      dispatch(
        getQuickProducts({
          pageNo: page,
          limit: limit,
          id: companyId,
          filterData: filterData,
          globalFilterValue: globalFilterValue,
        })
      );
    } else {
      dispatch(
        getQuickProducts({
          pageNo: page,
          limit: limit,
          id: companyId,
          filterData: filterData,
        })
      );
    }
  }

  const OnClickSearch = () => {
    console.log(globalFilterValue)
    getProduct()
  }

  const getSeverity = (product) => {
    switch (product.status) {
      case "Available":
        return "success";
      case "Unavailable":
        return "danger";
      default:
        return null;
    }
  };

  const itemTemplate = (product) => {
    return (
      <div className="w-half p-2"> 
      <Card header={cardheader} className="md:w-18rem fixed-height-card">
        <div className="card-img-container ">    
        {product.url ? <img
          className="card-img-top"
          src={product.url}
          alt={product.productName}
        />:
         <Skeleton width="20rem" height="20rem"></Skeleton>
        }
        </div>
      
        <div className="card-body d-flex flex-column">
          <h2 className="card-title">{product.productName}</h2>
          <h3 className="card-subtitle">{product.desc}</h3>
          <div className="cardtag">
            <div>
              <span className="text-1xl mt-1">₹</span>
              <span style={{ fontSize: "20px" }}>{product.price}</span>
            </div>
            <div className="mt-3">
              <Tag
                className="p-1 px-2"
                style={{ fontSize: "11px" }}
                value={product.status}
                severity={getSeverity(product)}
              ></Tag>
            </div>
          </div>
          <div className="mt-3">{productBodyTemplate(product)}</div>
        </div>
      </Card>
    </div>
    


    );
  };

  const cardheader = (product) => {
    <img
      className="card-img-top"
      src={product.url}
      alt={product.productName}
    />
  }

  const productBodyTemplate = (product) => {
    const existingCartItem = cartItemData?.find(
      (cartItem) => cartItem.id === product.id
    );
    console.log(product?.itemCount);
    if (existingCartItem?.itemCount > 0) {
      return (
        <div className="flex align-items-center gap-3">
          <div className="flex gap-2 align-items-center justify-content-between">
            <Button
              icon="pi pi-minus"
              className="w-2rem h-2rem __btn1"
              rounded
              text
              raised
              severity="secondary"
              aria-label="Bookmark"
              onClick={() => {
                dispatch(removeProductInCart(product));
              }}
            />
            <div className="w-1rem">
              <span className="">{existingCartItem.itemCount}</span>
            </div>
            <Button
              icon="pi pi-plus"
              className="w-2rem h-2rem __btn1"
              rounded
              text
              raised
              severity="secondary"
              aria-label="Bookmark"
              onClick={() => {
                dispatch(addProductInCart(product));
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <Button
          className="__btn1 p-1 px-4 "
          disabled={product.status === "Unavailable"}
          onClick={() => {
            dispatch(addProductInCart(product));
          }}
        >
          ADD
        </Button>
      );
    }
  };

  const gridItem1 = (product) => {
    return (
      <div className="mx-auto mb-2 w-22rem p-1 __productCard">
        <div className="p-4 border-1 surface-border surface-card border-round">
          <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <div className="flex align-items-center gap-2">
              <i className="pi pi-tag"></i>
              <span className="font-semibold" style={{ fontSize: "13px" }}>
                {product.categoryName}
              </span>
            </div>
            <Tag
              className="p-1 px-2"
              style={{ fontSize: "11px" }}
              value={product.status}
              severity={getSeverity(product)}
            ></Tag>
          </div>
          <div className="flex flex-column align-items-center gap-3 py-2">
            <img
              className="shadow-2 border-round __productImage"
              src={product.url}
              alt={product.productName}
            />
            <div className="text-1xl font-bold ">{product.productName}</div>
          </div>
          <div className="flex align-items-center justify-content-between h-2rem">
            <span className="text-1xl font-semibold">₹{product.price}</span>
            {productBodyTemplate(product)}
          </div>
        </div>
      </div>
    );
  };

  const gridItem = (product) => {
    return (
      <div className="mx-auto mb-2 lg:w-15rem w-22rem p-1 __productCard">
        <div className="border-1 w-full h-16rem flex flex-row surface-border surface-card border-round ">
          <div className="w-5 __gray p-2 flex justify-content-center align-items-center">
            <img
              style={{ width: "100%" }}
              className="__productImage"
              src={product.url}
              alt={product.productName}
            />
          </div>
          <div className="w-7 p-2 ">
            <div className=" ">
              <span className="font-semibold" style={{ fontSize: "16px" }}>
                {product.productName} this is normal text name for product name
              </span>
            </div>
            <div className="mt-3 flex flex-wrap align-items-center justify-content-between gap-2 ">
              <div className="flex align-items-center gap-2">
                <i className="pi pi-tag"></i>
                <span style={{ fontSize: "15px" }}>{product.categoryName}</span>
              </div>
            </div>
            <div className="mt-3">
              <Tag
                className="p-1 px-2"
                style={{ fontSize: "11px" }}
                value={product.status}
                severity={getSeverity(product)}
              ></Tag>
            </div>
            <div className="mt-3 flex">
              <span className="text-1xl mt-1">₹</span>
              <span style={{ fontSize: "30px" }}>{product.price}</span>
            </div>
            <div className="mt-3 ">{productBodyTemplate(product)}</div>
          </div>
        </div>
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex justify-content-end gap-1 lg:gap-2">
        <Dropdown
          value={selectedCategory}
          showClear
          onChange={(e) => setSelectedCategory(e.value)}
          options={catagories}
          optionLabel="categoryName"
          placeholder="Select a Category"
          className="w-9rem md:w-14rem  lg:ml-2"
        />
        <div className="flex align-items-center ">
          <span className="p-input-icon-right">
            <InputText
              placeholder="Search Product"
              className="w-10rem lg:w-full"
              onChange={(e) => setGlobalFilterValue(e.target.value)}
            />
            <i className={"pi pi-search cursor-pointer"} onClick={OnClickSearch} />
          </span>
        </div>
      </div>
    );
  };
  

  return (
    <div className="w-full mx-auto  mt-5">
        {modalLoad()}
      <div className="card w-full">
        <DataView
          value={productsData.length>0?productsData:productsDataSkelton}
          itemTemplate={itemTemplate}
          layout={layout}
          header={header()}
        />
      </div>
      <div className="card cust-pagination">
        <Paginator first={first} rows={rows} totalRecords={totalProductCount} rowsPerPageOptions={[5, 10, 15, 20]} onPageChange={onPageChange} />
      </div>
    </div>
  );
};

export default Products;
