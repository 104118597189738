import axios from "axios";
import baseUrl from "./server";
import store from '../store';



export const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: "*/*",
        "Content-Type": "application/json"
    }
});


export default axiosInstance;