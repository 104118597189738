import React from "react";
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./style.module.css";

function Header() {
  const navigate = useNavigate();
  const { totalItemInCart } = useSelector((state) => state.cartTable);
  const { companyData } = useSelector((state) => state.company);

  const headerStyle = {
    backgroundColor: companyData?.desc?.theme?.primaryColor || "#000",
    color: companyData?.desc?.theme?.secondaryColor || "#ffffff",
  };

  return (
    <div className={`${styles["__headerContainer"]}`} style={headerStyle}>
      <div className={`${styles["header"]} w-11 py-2 m-auto`}>
        <div className="flex justify-content-between align-items-center w-full h-full">
          <div
            className="min-w-min flex justify-content-start align-items-center"
            onClick={() => navigate("/")}
          >
            <img src={companyData?.url} alt="Company Logo" style={{ width: "40px" }} />
          </div>
          <div
            className="min-w-min flex justify-content-start align-items-center cursor-pointer"
            onClick={() => navigate("/cart")}
          >
            <i className="pi pi-shopping-cart p-overlay-badge" style={{ fontSize: "1.7rem" }}>
              {totalItemInCart !== 0 ? (
                <Badge value={totalItemInCart} severity="danger"></Badge>
              ) : (
                <></>
              )}
            </i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
