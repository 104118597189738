// CustomButton.js

import { Button } from "primereact/button";
import "./index.css";

export const CustomButton = ({
  label = null,
  icon = null,
  onClick = () => {},
  variant = "",
  type = "",
  disabled = false,
  themeColors = {} // Add a prop for theme colors
}) => {
  const { buttonbg, buttonColor } = themeColors;

  // Create dynamic styles for the button
  const buttonStyle = {
    backgroundColor: buttonbg || '#075E54',
    color: buttonColor || '#ffffff',
  };

  return (
    <Button
      type={type}
      label={label}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      style={buttonStyle} // Apply dynamic styles for the button
      className={`skalebot-button flex ${variant}`}
    />
  );
};
