import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartItemData: [],
    loading: false,
    totalItemInCart:0,
    totalPriceOfItemInCart:0,
    selectedCartItem: null,
    page: 0,
    limit: 10,
    mode: null,
  };
  
const cartTableSlice = createSlice({
    name: "cartTable",
    initialState,
    reducers: { 
      setProductInCart(state,action){
        console.log(action.payload)
        state.totalPriceOfItemInCart=action.payload.totalPriceOfItemInCart
        state.cartItemData=action.payload.cartItemData
        state.totalItemInCart=action.payload.totalItemInCart
      },

      addProductInCart(state, action) {
        state.totalItemInCart += 1;
        if (state.cartItemData.length===0) {
            const cartItem = {...action.payload}
            cartItem['itemCount'] = 1;
            state.cartItemData = [cartItem, ...state.cartItemData]; 
            state.totalPriceOfItemInCart += cartItem['price']
        }else{     
                const existingCartItem = state.cartItemData.find(cartItem => cartItem.id === action.payload.id)
                if (existingCartItem) {
                    existingCartItem['itemCount'] += 1;
                    state.totalPriceOfItemInCart += existingCartItem['price']
                }else{
                    const addcartItem =  {...action.payload}
                    addcartItem['itemCount'] = 1;
                    state.totalPriceOfItemInCart += addcartItem['price']
                    state.cartItemData = [addcartItem, ...state.cartItemData]; 
                }
        }
        const localStorageData = {
          totalPriceOfItemInCart:state.totalPriceOfItemInCart,
          cartItemData:state.cartItemData,
          totalItemInCart:state.totalItemInCart
        }
        localStorage.setItem("deskalaCartItem",JSON.stringify(localStorageData))
      },
      removeProductInCart(state, action) {
        const existingCartItem = state.cartItemData.find(cartItem => cartItem.id === action.payload.id)
        if (existingCartItem && existingCartItem['itemCount']>0) {
            state.totalItemInCart -= 1;
            existingCartItem['itemCount'] -= 1;
            state.totalPriceOfItemInCart -= existingCartItem['price']
        }
        const localStorageData = {
          totalPriceOfItemInCart:state.totalPriceOfItemInCart,
          cartItemData:state.cartItemData,
          totalItemInCart:state.totalItemInCart
        }
        localStorage.setItem("deskalaCartItem",JSON.stringify(localStorageData))
      },  
      delProductInCart(state, action) {
        const existingCartItem = state.cartItemData.find(cartItem => cartItem.id === action.payload.id)
        if (existingCartItem && existingCartItem['itemCount']>0) {
            state.totalItemInCart -= 1*existingCartItem['itemCount'];
             
            state.totalPriceOfItemInCart -= existingCartItem['itemCount']*existingCartItem['price']
        }
        const cartItem = state.cartItemData.filter(cartItem => cartItem.id !== action.payload.id);
        state.cartItemData = cartItem;
        const localStorageData = {
          totalPriceOfItemInCart:state.totalPriceOfItemInCart,
          cartItemData:cartItem,
          totalItemInCart:state.totalItemInCart
        }
        console.log(localStorageData)
        localStorage.setItem("deskalaCartItem",JSON.stringify(localStorageData))
      },     
    },
  });
  
  export const {addProductInCart,removeProductInCart,setProductInCart,delProductInCart } = cartTableSlice.actions;
  export default cartTableSlice.reducer;
  