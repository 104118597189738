import React, { useEffect, useState } from 'react';
import { Button } from "primereact/button";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
const Footer = () => {
  const { companyData } = useSelector((state) => state.company);
  const [style, setStyle] = useState({});

  useEffect(() => {
    // Check if theme object exists in companyData.desc
    if (companyData?.desc?.theme) {
      // Extract colors from the received data
      const { primaryColor, secondaryColor, buttonbg, buttonColor } = companyData?.desc?.theme;

      // Update the dynamic styles
      setStyle({
        backColor: primaryColor || '#00000',
        color: secondaryColor || '#ffffff', // Default to white if secondaryColor is not provided
        buttonbgColor: buttonbg || '#00000',
        buttonColor: buttonColor || '#ffffff', // Use the primaryColor as the button color
      });
    }
  }, [companyData]);

  const sendWhatsAppMessage = () => {
    const phoneNumber = companyData.personalPhone;
    const bussinessNumber = companyData.phone;
    const scanNumber = bussinessNumber? bussinessNumber: phoneNumber;
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${scanNumber}&text=Hi`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mt-5">
      <div
        className="card gap-2 flex flex-column justify-content-center __footer py-6"
        style={{ backgroundColor: style.backColor, color: style.color }}
      >
        <div className="footer-grid">
          <div className="footer-item-left">
            <span className="footer-contact">Contact Us</span>
            <p>
              Have questions or want to get in touch with us? We’re here to
              assist you
            </p>
            <Button
              className="__btn flex"
              label="Connect in Whatsapp"
              style={{
                backgroundColor: style.buttonbgColor,
                color: style.buttonColor,
              }}
              onClick={sendWhatsAppMessage}
            />
          </div>
          <div className="footer-item">
            <p className="my-3" style={{ fontSize: "25px" }}>
              {companyData?.companyName}
            </p>
            <p className="footer-contact">Address</p>
            <p>{companyData?.desc?.contactInfo?.address}</p>
            <p className="my-0">{companyData?.location}</p>
            <p>{companyData?.desc?.contactInfo?.email}</p>
            <p>{companyData?.personalPhone}</p>
          </div>
          <div className="footer-item">
            <p className="qrTitle">Scan to Connect WhatsappBot</p>
            <QRCode
              id="generatedQrCode"
              size={150}
              style={{ height: "100%", display: "block", margin: "auto" }}
              value={`https://wa.me/${companyData?.phone}?text=Hi`}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-column lg:flex-row justify-content-center lg:justify-content-between align-items-center pt-2 px-6">
        <p className="mt-0">Copyright 2023</p>
        <p className="mt-0">Powered by Skalebot – Deskala</p>
      </div>

    </div>
  );
};

export default Footer;
