import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tag } from "primereact/tag";
import "./style.css";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  addProductInCart,
  delProductInCart,
  removeProductInCart,
} from "../../reducers/cartTableSlice";
import OrderForm from "../../componenets/Form/OrderForm";

const Cart = () => {
  const { cartItemData, totalPriceOfItemInCart } = useSelector(
    (state) => state.cartTable
  );
  const { totalItemInCart } = useSelector((state) => state.cartTable);
  const { companyData } = useSelector((state) => state.company);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("list");
  const [style, setStyle] = useState({}); 
  const getSeverity = (product) => {
    switch (product.status) {
      case "Available":
        return "success";

      case "Unavailable":
        return "danger";

      default:
        return null;
    }
  };

  useEffect(() => {
    console.log("cartitem", cartItemData, totalPriceOfItemInCart);
    console.log(
      "cartitemlocalStroge",
      JSON.parse(localStorage.getItem("deskalaCartItem"))
    );
  }, [cartItemData]);

  useEffect(() => {
    // Check if theme object exists in companyData.desc
    if (companyData?.desc?.theme) {
      // Extract colors from the received data
      const { buttonbg, buttonColor } = companyData?.desc?.theme;

      // Update the dynamic styles for the button
      setStyle({
        buttonbgColor: buttonbg || '#075E54',
        buttonColor: buttonColor || '#ffffff', // Use the primaryColor as the button color
      });
    }
  }, [companyData]);

  const itemTemplate = (product) => {
    return (
      <div className="mx-auto w-full mt-2 p-1 __productCard">
        <div className=" w-full flex flex-row surface-border surface-card border-round ">
          <div className="w-5 __gray p-2 flex justify-content-center align-items-center">
            <img
              // style={{height:"200px" }}
              className="__productImage w-full lg:w-6"
              src={product.url}
              alt={product.productName}
            />
          </div>
          <div className="w-10 p-2 ">
            <div className="flex justify-content-between ">
              <span className="font-semibold" style={{ fontSize: "16px" }}>
                {product.productName}
              </span>
            </div>
            <div className=" ">
              <span className="" style={{ fontSize: "14px" }}>
                {product.desc}
              </span>
            </div>
            <div className="mt-3 flex flex-wrap align-items-center justify-content-between gap-2 ">
              <div className="flex align-items-center gap-2">
                <i className="pi pi-tag"></i>
                <span style={{ fontSize: "15px" }}>{product.categoryName}</span>
              </div>
            </div>
            <div className="mt-3">
              <Tag
                className="p-1 px-2"
                style={{ fontSize: "11px" }}
                value={product.status}
                severity={getSeverity(product)}
              ></Tag>
            </div>
            <div className="mt-3 flex">
              <span className="text-1xl mt-1">₹</span>
              <span style={{ fontSize: "30px" }}>{product.price}</span>
            </div>
            <div className="mt-3 ">{productBodyTemplate(product)}</div>
          </div>
        </div>
      </div>
    );
  };

  const productBodyTemplate = (product) => {
    const existingCartItem = cartItemData.find(
      (cartItem) => cartItem.id === product.id
    );
    console.log(product?.itemCount);
    if (cartItemData?.length > 0) {
      return (
        <div className="flex align-items-center gap-3">
          <div className="flex gap-2 align-items-center justify-content-between">
            <Button
              icon="pi pi-minus"
              className="w-2rem h-2rem __btn1"
              rounded
              text
              raised
              severity="secondary"
              aria-label="Bookmark"
              disabled={CheckDisabled(existingCartItem?.itemCount)}
              onClick={() => {
                dispatch(removeProductInCart(product));
              }}
            />
            <div className="w-1rem">
              <span className="">{existingCartItem.itemCount}</span>
            </div>
            <Button
              icon="pi pi-plus"
              className="w-2rem h-2rem __btn1"
              rounded
              text
              raised
              severity="secondary"
              aria-label="Bookmark"
              onClick={() => {
                dispatch(addProductInCart(product));
              }}
            />
            <Button
              label="Remove"
              className="removecart-button flex p-button p-component"
              text
              onClick={() => {
                dispatch(delProductInCart(product));
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex w-full flex-column align-items-center justify-content-center">
          <img className="lg:w-2 w-5 " src="./shine-shopping-cart.png" />
          <h4>
            Your cart is currently empty,Start Shopping now to fill it with
            amazing products .{" "}
          </h4>
          <Button
            icon="pi pi-plus"
            className=" "
            label="Shop Now"
            severity="secondary"
            onClick={() => navigate("/")}
          />
        </div>
      );
    }
  };

  const imageBodyTemplate = (product) => {
    return (
      <img
        src={`${product.url}`}
        alt={product.url}
        className="w-4rem h-4rem shadow-2 border-round"
      />
    );
  };

  const statusBodyTemplate = (product) => {
    return <Tag value={product.status} severity={getSeverity(product)}></Tag>;
  };

  const qanBodyTemplate = (product) => {
    return (
      <div className="flex align-items-center gap-3">
        <div className="flex gap-2 align-items-center justify-content-center">
          <Button
            icon="pi pi-minus"
            className="w-2rem h-2rem"
            rounded
            text
            raised
            severity="secondary"
            aria-label="Bookmark"
            onClick={() => {
              dispatch(removeProductInCart(product));
            }}
          />
          <div className="w-1rem">
            <span>{product.itemCount}</span>
          </div>
          <Button
            icon="pi pi-plus"
            className="w-2rem h-2rem"
            rounded
            text
            raised
            severity="secondary"
            aria-label="Bookmark"
            onClick={() => {
              dispatch(addProductInCart(product));
            }}
          />
        </div>
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-wrap align-items-center justify-content-between gap-2">
        <span className="text-lg text-900 font-bold">Shopping Cart</span>
      </div>
    );
  };

  const footer = () => {
    return (
      <div className="flex justify-content-center lg:justify-content-end ">
        Total Price({totalItemInCart} items) : {totalPriceOfItemInCart}
      </div>
    );
  };
  function CheckDisabled(count) {
    return !(count > 1);
  }

  return (
    <div className="w-full ">
      <div className="w-11 mx-auto mt-5">
        <Button
          icon="pi pi-arrow-left"
          rounded
          outlined
          severity="secondary"
          aria-label="Bookmark"
          onClick={() => navigate("/")}
        />
      </div>

      <div className="w-11 mx-auto mt-4 flex flex-column lg:flex-row  justify-content-between gap-3">
        <div className="card  w-full">
          <Card>
            {cartItemData.length !== 0 ? (
              <DataView
                value={cartItemData}
                itemTemplate={itemTemplate}
                layout={layout}
                footer={footer()}
                header={header()}
              />
            ) : (
              companyData !== null && (
                <div className="flex w-full flex-column align-items-center justify-content-center">
                  <img
                    className="lg:w-1 w-5 "
                    src="./shine-shopping-cart.png"
                  />
                  <h4>
                    Your cart is currently empty,start shopping now to fill it
                    with amazing products .{" "}
                  </h4>
                  <Button
                  className="mt-2 __btn2"
                  icon="pi pi-plus"
                  label="Shop Now"
                  onClick={() => navigate("/")}
                  style={{
                    backgroundColor: style.buttonbgColor,
                    color: style.buttonColor,
                  }}// Apply dynamic styles for the button
                 />
                </div>
                // <div>
                //   <h1>Thanks for Shopping</h1>
                //   <h1>Your Order is Placed Successfully.</h1>
                // </div>
              )
            )}
          </Card>
        </div>

        {cartItemData.length !== 0 && (
          <div className="card w-full lg:w-6">
            <Card title="Order" className="pt-4">
              <OrderForm />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
